import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { APP_BASE_HREF } from '@angular/common';

import { ROUTES } from './app.routes';
import { AppComponent } from './app.component';

import { LayoutsModule } from './components/common/layouts/layouts.module';
import { LocationMapComponent } from './location-map/location-map.component';
import { LocationOverviewComponent, LocationOverviewGuard } from './location-overview/location-overview.component';
import { AgmCoreModule } from '@agm/core';
import { WellDetailsComponent } from './components/well-details/well-details.component';
import { WellAttachmentsComponent } from './components/well-attachments/well-attachments.component';
import { LocationDetailComponent } from './location-detail/location-detail.component';
import { BroLocationDetailComponent } from './bro-location-detail/bro-location-detail.component';
import { LocationGroupDetailComponent } from './location-group-detail/location-group-detail.component';
import { LocationGroupDetailGuard } from './location-group-detail/location-group-detail-guard';
import { WaterlevelDisplayModule } from './waterlevel-display/waterlevel-display.module';
import { MeasurementExportModule } from './components/measurement-export/measurement-export.module';

import { AuthGuard } from './auth/auth-guard.service';
import { TokenGuard } from './auth/token-guard.service';
import { AuthService } from './auth/auth.service'
import { CallbackComponent } from './callback/callback.component';
import { JwtInterceptor } from './util/JwtInterceptor';
import { ImpersonateInterceptor } from './util/impersonate.interceptor';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { FlowmeterDisplayModule } from './flowmeter-display/flowmeter-display.module';
import { Daterangepicker } from 'ng2-daterangepicker';
import { DaterangepickerConfig } from 'ng2-daterangepicker';
import { MeasurementIntervalManagerComponent } from './measurement-interval-manager/measurement-interval-manager.component';
import { LocationCompareComponent, LocationCompareGuard } from './location-compare/location-compare.component';
import { WaterlevelCompareComponent } from './waterlevel-compare/waterlevel-compare.component';
import { IsSelected } from './location-compare/selectedLocations';
import { CompareService } from "./services/compare-service";
import { WelcomeComponent, WelcomeGuard } from './landing/welcome.component';
import { LoginComponent, LoginGuard } from './landing/login.component';
import { SignupComponent, SignupGuard } from './landing/signup.component';
import { DateRangePickerComponent } from './components/date-range-picker/date-range-picker.component';
import { AutofocusDirective } from './directives/autofocus.directive';
import { AutoHeightDirective } from './directives/auto-height.directive';
import { ProfileComponent } from './components/profile/profile.component';
import { LocationService } from "./services/location.service";
import { LocationAttachmentsService } from "./services/location-attachments.service";
import { UserService } from "./services/user.service";
import { KNMIService } from "./services/knmi-service";
import { UserFileService } from "./services/user-file.service";
import { DeploymentService } from "./services/deployment.service";
import { ContenteditableDirective } from './directives/contenteditable.directive';
import { UserImageComponent, UserImageGalleryComponent } from './components/user-image-gallery/user-image-gallery.component';
import {AutocompleteLibModule} from 'angular-ng-autocomplete';

import { LightboxModule } from 'ngx-lightbox';
import { NotifierModule } from 'angular-notifier';
import { DeploymentDetailComponent } from './deployment-detail/deployment-detail.component';
import { DeploymentListComponent } from './deployment-list/deployment-list.component';
import { DeploymentStatusComponent } from './deployment-status/deployment-status.component';
import { LocationLogComponent } from './location-log/location-log.component';

import * as Highcharts from 'highcharts';

import HC_stock from 'highcharts/modules/stock';
HC_stock(Highcharts);

import HC_exporting from 'highcharts/modules/exporting';
import HC_offline_exporting from 'highcharts/modules/offline-exporting';
HC_exporting(Highcharts);
HC_offline_exporting(Highcharts);

// import HC_indicators from 'highcharts/indicators/indicators-all'
// HC_indicators(Highcharts)

// import HC_dragpanes from 'highcharts/modules/drag-panes'
// HC_dragpanes(Highcharts);

import HC_annotations from 'highcharts/modules/annotations';
HC_annotations(Highcharts);

// import HC_priceindicator from 'highcharts/modules/price-indicator'
// HC_priceindicator(Highcharts);

// import HC_fullscreen from 'highcharts/modules/full-screen'
// HC_fullscreen(Highcharts);

// import * as HC_exportdata from 'highcharts/modules/export-data';
// HC_exportdata(Highcharts);

// import HC_stocktools from 'highcharts/modules/stock-tools';
// HC_stocktools(Highcharts);

// declare var require: any;
// let HC_Annotations = require('highcharts/modules/annotations');
// HC_Annotations(Highcharts);

Highcharts.setOptions({
  time: {
    // Show dates, axis labels, etc. in local time
    useUTC: false
  }
});

import { LocationLogAuthorTypeComponent } from './location-log-author-type/location-log-author-type.component';
import { BlikLoaderSmallComponent } from './blik-loader-small/blik-loader-small.component';
import { FloorPipe } from './pipes/floor.pipe';
import { LocalStorageService } from './services/localstorage.service';
import { UserManagementComponent, UserManagementGuard } from './user-management/user-management.component';
import { UserSettingsComponent } from './components/user-settings/user-settings.component';
import { LocationUserSettingsComponent } from './components/location-user-settings/location-user-settings.component';
import { LocationStatusComponent } from './components/location-status/location-status.component';
import { AgmJsMarkerClustererModule } from '@agm/js-marker-clusterer';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { JobService } from './services/job.service';


@NgModule({
  declarations: [
    AppComponent,
    LocationMapComponent,
    MeasurementIntervalManagerComponent,
    LocationDetailComponent,
    BroLocationDetailComponent,
    LocationGroupDetailComponent,
    LocationOverviewComponent,
    WellDetailsComponent,
    WellAttachmentsComponent,
    CallbackComponent,
    LocationCompareComponent,
    WaterlevelCompareComponent,
    IsSelected,
    WelcomeComponent,
    LoginComponent,
    SignupComponent,
    DateRangePickerComponent,
    UserImageComponent,
    UserImageGalleryComponent,
    AutofocusDirective,
    AutoHeightDirective,
    ProfileComponent,
    ContenteditableDirective,
    DeploymentDetailComponent,
    DeploymentListComponent,
    DeploymentStatusComponent,
    LocationLogComponent,
    LocationLogAuthorTypeComponent,
    BlikLoaderSmallComponent,
    FloorPipe,
    UserManagementComponent,
    UserSettingsComponent,
    LocationUserSettingsComponent,
    LocationStatusComponent,
  ],
  imports: [
    FormsModule, ReactiveFormsModule,
    BrowserModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyCFUbzXXZI07HCtu1j6RzFe3iwuWFfLk2M',
      libraries: ['places', 'drawing']
    }),
    HttpClientModule,
    LayoutsModule,
    Daterangepicker,
    WaterlevelDisplayModule,
    FlowmeterDisplayModule,
    MeasurementExportModule,
    RouterModule.forRoot(ROUTES,{useHash: false}),
    NotifierModule.withConfig( {
      position: {
        horizontal: {
          position: 'right'
        },
        vertical: {
          position: 'bottom'
        }
      },
      behaviour: {
        autoHide: 15000,
        onMouseover: 'resetAutoHide',
        stacking: 4
      },
    } ),
    LightboxModule,
    AutocompleteLibModule,
    AgmJsMarkerClustererModule,
    BrowserAnimationsModule,
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ImpersonateInterceptor, multi: true },
    { provide: APP_BASE_HREF, useValue : '/' },
    AuthService,
    AuthGuard,
    LoginGuard,
    SignupGuard,
    TokenGuard,
    WelcomeGuard,
    UserManagementGuard,
    LocationOverviewGuard,
    LocationGroupDetailGuard,
    LocationCompareGuard,
    Daterangepicker,
    DaterangepickerConfig,
    AgmCoreModule,
    CompareService,
    LocationService,
    LocationAttachmentsService,
    UserFileService,
    UserService,
    KNMIService,
    DeploymentService,
    LocalStorageService,
    JobService,
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
