import { Component, Input, Output, EventEmitter, SimpleChange } from '@angular/core';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import * as Highcharts from 'highcharts';
import * as moment from 'moment';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { colours } from 'app/util/colours';
import { Defaults } from "../util/defaults";
import { BlikLocation, BroLocation, LocationID, LocationService, MeasurementData } from "../services/location.service";
import { NotifierService } from 'angular-notifier';
import { KNMIStationDataEntry } from 'app/services/knmi-service';

// Sizes of the grass image
const GRASS_HEIGHT = 48;
const GRASS_WIDTH = 176;
// Minimum zoom range (3h)
const MIN_ZOOM_RANGE_MS = 1000 * 60 * 60 * 3;
/**
 * Returns the difference between two given arrays. That is, elements that occur in the first, but not in the second array.
 * @param {array<any>} first
 * @param {array<any>} second
 */
function diff(first, second) {
  return first.filter(a => !second.find(b => a == b));
}

function meterToCentimeter(value: number, decimals = 0) {
  return Highcharts.numberFormat(value * 100, decimals);
}

@Component({
  selector: 'app-waterlevel-compare',
  templateUrl: './waterlevel-compare.component.html'
})
export class WaterlevelCompareComponent {
  @Input() locations: LocationID[];
  @Input() highlighted: LocationID | null;
  @Input() locationData: (BlikLocation | BroLocation)[];
  @Input() knmiData: KNMIStationDataEntry[];
  @Output() dateSelection: EventEmitter<Highcharts.AxisSetExtremesEventObject> = new EventEmitter<Highcharts.AxisSetExtremesEventObject>();
  @Output() dateRange: EventEmitter<any> = new EventEmitter();

  static showInNapReference = false;
  referenceLevelButtonText = this.getReferenceLevelButtonText();

  // colorDirt = 'rgba(158, 77, 7, 0.7)'
  colorDirt = 'rgba(156, 103, 59, 1.0)'
  // colorWater = 'rgba(60, 120, 189, 0.5)';
  colorWater = 'rgba(152, 182, 217, 1.0)';
  colorWaterLine = 'rgba(60, 120, 189, 1.0)';

  // Highcharts
  chartOptions = {
    credits: {
      enabled: true,
      text: "Blik Sensing",
      href: "https://bliksensing.nl"
    },
    navigation: {
    //   bindings: {}
      events: {
        selectButton: function (event) {
            event.button.classList.add('highcharts-active');
        },
        deselectButton: function (event) {
            event.button.classList.remove('highcharts-active');
        }
      }
    },
    // stockTools: {
    //   gui: {
		// 		enabled: true, // disable the builtin toolbar
		// 	}
    // },
    chart: {
      backgroundColor: 'transparent',
      type: 'area',
      zoomType: 'x',
      // spacingTop is needed to render the grass on top of the chart
      spacingTop: GRASS_HEIGHT,
      events: {
        redraw: (event) => {
          this.renderGrass(event);
          setTimeout(function() {
            window.dispatchEvent(new Event('resize'));
          }, 0.5);
        },
      },
    },
    rangeSelector: {
      enabled: true,
      allButtonsEnabled: true,
      floating: true,
      verticalAlign: 'bottom',
      selected: 2
    },
    navigator: {
      enabled: true,
      yAxis: {
        reversed: false
      },
      series: {
        type: 'line',
        fillOpacity: 0.4,
        dataGrouping: {
            smoothed: false
        },
        lineWidth: 2,
        marker: {
            enabled: false
        },
      },
      adaptToUpdatedData: false,
    },
    plotOptions: {
      area: {
        fillColor: this.colorDirt,
        // The area between the line and the threshold value is filled.
        // We want everything above the line to look like earth, so use Infinity.
        threshold: Infinity
      }

    },
    colors: colours,
    title: {text: ""},
    tooltip: {
      xDateFormat: Defaults.FORMAT_HIGHCHARTS_DATETIME,
      pointFormat: Defaults.FORMAT_HIGHCHARTS_SERIES_TWO_DECIMALS
    },
    xAxis: {
      type: 'datetime',
      // Change formats for the zoomlevels that we want to display differently than the default
      dateTimeLabelFormats: {
        day: Defaults.FORMAT_HIGHCHARTS_MONTHDAY,
        month: Defaults.FORMAT_HIGHCHARTS_MONTHYEAR
      },
      minRange: 0,
      title: {
        text: 'Datum'
      },
      events: {
        setExtremes: (e: Highcharts.AxisSetExtremesEventObject) => {
          if('max' in e && 'min' in e) {
            this.dateSelection.emit(e);
          }
        }
      },
      min: moment().unix() - 365 * 24 * 60 * 60,
      max: moment().unix(),
    },
    yAxis: [{
        labels: {
          format: Defaults.FORMAT_HIGHCHARTS_VALUE
        },
        title: {
          text: this.getMeasurementSeriesLabel()
        },
      }, {
        showEmpty: false,
        min: 0,
        title: {
          text: "Neerslag (mm per uur)"
        },
      }
    ],
    series: [],
    exporting: {
      chartOptions: {
        chart: {
          backgroundColor: 'rgb(245,245,245)'
        }
      },
      buttons: {
        contextButton: {
          menuItems: ["printChart", "separator", "downloadPNG", "downloadJPEG", "downloadPDF"]
        }
      },
      sourceWidth: 1920,
      sourceHeight: 1080,
    },
    legend: {
      width: "35%",
      align: "center",
      alignColumns: false,
    }
  } as Highcharts.Options;

  // chart = new Chart(this.chartOptions);
  public chart;

  ngOnInit() {
    this.chart = Highcharts.chart('waterlevelcomparechart', this.chartOptions);
  }

  constructor(private locationService: LocationService,
              private notifier: NotifierService
              ) {
    this.fetchLocationData = this.fetchLocationData.bind(this);
    this.addSeries = this.addSeries.bind(this);
  }

  /**
   * Handle changes in the input variables.
   * @param {{[p: string]: SimpleChange}} changes
   */
  ngOnChanges(changes: { [propKey: string]: SimpleChange }) {
    const { locations, locationData, knmiData, highlighted } = changes;

    // Changes in selected locations shown in the graph.
    // Fetch data for added locations.
    // Removed locations can simply be dropped form the chart.
    if (locations) {
      let current = locations.currentValue;
      let previous = locations.previousValue || [];

      // Find added ids and fetch data for each of them
      let added = diff(current, previous);
      this.fetchData(added);

      // Find removed ids and remove their series from the chart
      let removed = diff(previous, current);
      removed.forEach(id => {
        this.series[id].remove(false);
        delete this.series[id];
        delete this.data[id];
      });
      if (removed.length > 0) {
        this.updateChart();
      }
    }

    if (locationData) {
      this.addSeries();
    }

    if (knmiData) {
      this.updateKnmiData();
    }

    // Check for changes in the highlighted series
    if (highlighted && this.chart) {
      if (highlighted.previousValue) {
        const previouslyHighlightedSeries = this.series[highlighted.previousValue];
        if (previouslyHighlightedSeries) {
          previouslyHighlightedSeries.setState(undefined);
        }
      }

      if (highlighted.currentValue) {
        const currentlyHighlightedSeries = this.series[highlighted.currentValue];
        if (currentlyHighlightedSeries) {
          currentlyHighlightedSeries.setState('hover');
        }
      }
      
      this.chart.redraw();
    }
  }

  data: { [id: number] : MeasurementData<false, true, false, false, true, false> } = {};
  series: { [id: number] : Highcharts.Series } = {};
  knmiDataSeries: Highcharts.Series = null;

  /**
   * Fetch data for each location id.
   */
  fetchData(locations : Array<LocationID> = this.locations) {
    locations.forEach(id => {
      if (!(id in this.data)) {
        this.fetchLocationData(id).subscribe(data => {
          this.data[id] = data;
          this.addSeries();
        },
        error => {
          this.notifier.notify("error", "Fout bij het ophalen van metingen.");
        })
      } else {
        this.addSeries();
      }
    });
  }

  updateKnmiData() {
    if (!this.chart) return;

    if (!this.knmiData) {
      if (this.knmiDataSeries) {
        this.knmiDataSeries.remove(true);
        this.knmiDataSeries = null;
      }
    } else {
      var knmiData: any = this.knmiData;
      var seriesOptions: Highcharts.SeriesOptionsType = {
        name: "Neerslag (mm per uur)",
        data: knmiData.map(d => [
          moment(d.time).valueOf(),
          d.rain / 1000
        ]),
        color: 'rgb(0,128,255)',
        type: 'column',
        yAxis: 1
      };
      if (this.knmiDataSeries) {
        this.knmiDataSeries.update(seriesOptions, true);
      } else {
        this.knmiDataSeries = this.chart.addSeries(seriesOptions, true, false);
      }
    }
  }

  extremesSet = false;

  updateChart() {
    if (!this.chart) {
      return;
    }

    // When all data is fetched, set the min/max and redraw thechart
    var minTime = moment().unix() - 365 * 24 * 60 * 60;
    var maxTime = moment().unix();

    if (!this.extremesSet) {
      // By default, set it to half a year
      this.chart.xAxis[0].setExtremes(minTime * 1000, maxTime * 1000);
      this.extremesSet = true;
    }

    if (this.data) {
      minTime = Math.min(...Object.keys(this.data).map(k => this.data[k].measurements.timestamps[0] as number));
      maxTime = Math.max(...Object.keys(this.data).map(k => this.data[k].measurements.timestamps[this.data[k].measurements.timestamps.length-1] as number));
    }

    this.dateRange.emit({start: moment(minTime * 1000), end: moment(maxTime * 1000)});

    this.chart.yAxis[0].update({max: WaterlevelCompareComponent.showInNapReference ? undefined : 0}, false);
  }

  fetchLocationData(id) {
    return this.locationService.getMeasurements(id, null, null, {
      referenceMeasurements: false,
      waterLevel: true,
      airWaterMeasurements: false,
      flowMeasurements: false,
      invalidated: true,
      onlyValidated: false,
    });
  }

  /**
   * Create a series object with the data, find the location name based on the given id and add to the chart.
   */
  addSeries() {
    if (!this.locationData) {
      return;
    }

    for (let id in this.data) {
      let data = this.data[id];
      if (!(id in this.series)) {
        let measurements = data.measurements;
        let timestamps = measurements.timestamps;
        let measurementsMmGround = measurements.waterGround_mm;
        let measurementsMmNap = measurements.waterNAP_mm;
        let measurementsMeters = (WaterlevelCompareComponent.showInNapReference ? measurementsMmNap : measurementsMmGround).map(e => (e === null ? null : e * 1e-3));

        let location = this.locationData.find(l => l.id == id);
        let locationName = location ? location.name || "Onbekende locatie" : id;
        let series: Highcharts.SeriesOptionsType = {
          type: "line",
          name: locationName,
          data: timestamps.map((t, i) => [t * 1000, measurementsMeters[i]]),
          showInNavigator: true,
        };
        this.series[id] = this.chart.addSeries(series, false, false);
      }
    }
    this.updateChart();
  }

  updateChartLabel() {
    this.chart.update({
      yAxis: {
        title: {text: this.getMeasurementSeriesLabel()}
      }
    });
  }

  toggleReferenceLevel() {
    WaterlevelCompareComponent.showInNapReference = !WaterlevelCompareComponent.showInNapReference;
    this.referenceLevelButtonText = this.getReferenceLevelButtonText();
    for (let i in this.series) {
      this.series[i].remove(false);
      delete this.series[i];
    }
    this.updateChartLabel();
    this.fetchData();
  }

  getReferenceLevelButtonText(): any {
    if (WaterlevelCompareComponent.showInNapReference) {
      return 'Toon relatief aan maaiveld';
    } else {
      return 'Toon relatief aan NAP';
    }
  }

  getMeasurementSeriesLabel() {
    return `Peil (m ${WaterlevelCompareComponent.showInNapReference ? 'NAP' : 'maaiveld'})`;
  }

  getReferenceMeasurementSeriesLabel() {
    return `Referentiemeting (m ${WaterlevelCompareComponent.showInNapReference ? 'NAP' : 'maaiveld'})`;
  }


  grassImages; // References to the SVG elements on top of the chart

  renderGrass(event) {
    // Get the Highcharts SVGRenderer
    let renderer = event.target.renderer;

    // Remove any previous grass image
    if (this.grassImages && this.grassImages.length > 0) {
      this.grassImages.forEach(img => {
        img.added && img.destroy();
      });
    }

    // Don't render grass for NAP
    if (WaterlevelCompareComponent.showInNapReference) {
      return;
    }

    // If there is any data, determine the min and max of the xAxis
    if (event.target.xAxis[0]) {
      let xAxis = event.target.xAxis[0];
      if (xAxis.dataMin && xAxis.dataMax) {
        // extremes is an object containing dataMin, dataMax and (if zoomed) userMin, userMax
        let extremes = xAxis.getExtremes();

        // dataMin and dataMax are coordinates on the xAxis (in milliseconds),
        // so use the Axis.toPixels() function to convert to screen coordinates.
        let minX = xAxis.toPixels(xAxis.min);
        let maxX = xAxis.toPixels(xAxis.max);
        let width = maxX-minX;

        let numberOfImages = Math.floor(width/GRASS_WIDTH);
        this.grassImages = Array(numberOfImages);
        for (let i = 0; i <= numberOfImages; i ++) {
          let grassImage = renderer.image('assets/images/grass.png', minX + i*GRASS_WIDTH, 0, GRASS_WIDTH, GRASS_HEIGHT);

          // Clip the last image based on the remaining width
          if (i === numberOfImages) {
            let start = minX + numberOfImages*GRASS_WIDTH;
            let remainingWidth = width % GRASS_WIDTH;
            let clipRect = renderer.clipRect(start, 0, remainingWidth, GRASS_HEIGHT);
            grassImage.clip(clipRect);
          }
          grassImage.add();
          this.grassImages[i] = grassImage;
        }
      }
    }
  };
}
